import {roleGuard} from "@/helper/router.helper";

export default {
    routes: [
        {
            path: "",
            component: () => import("./WrapperSettings.vue"),
            redirect: "/settings-company-data",
            name: "settings",
            children: [
                {
                    path: "/settings-company-data",
                    name: "company-data",
                    component: () => import("./companyData/CompanyDetails.vue"),
                    meta: {
                        title: "SIDEBAR.COMPANY_DATA",
                        roles: ["admin"],
                    },
                    beforeEnter: roleGuard,
                },

                {
                    path: "/settings-delegates-data",
                    name: "delegates-data",
                    component: () => import("./Authorized/AuthorizedList.vue"),
                    meta: {
                        title: "SIDEBAR.DELEGATES_DATA",
                        roles: ["admin"],
                    },
                    beforeEnter: roleGuard,
                },
                {
                    path: "/subscription",
                    name: "subscription",
                    component: () => import("./UserSubscription.vue"),
                    meta: {
                        title: "SIDEBAR.PAYMENTS",
                        roles: ["admin"],
                    },
                    beforeEnter: roleGuard,
                },
                {
                    path: "/subscription/payments/callback",
                    name: "subscription-payment-callback",
                    component: () => import("./PaymentCallback.vue"),
                    meta: {
                        title: "SIDEBAR.PAYMENTS",
                        roles: ["admin"],
                    },
                    beforeEnter: roleGuard,
                },

            ],
        },

    ],
};
