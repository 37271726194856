import { guestGuard, roleGuard } from "@/helper/router.helper";
import contractsRoute from "@/views/Companies/Contracts/contracts.route";
import contractsApprovesRoute from "@/views/Companies/ContractApproves/contracts.route.js";
import contractsSigneRoute from "@/views/Companies/ContractSign/contracts.route.js";
import settingsRoute from "@/views/Companies/Settings/settings.routes";
import userProfile from "@/views/Companies/UserProfile/userProfile.routes";
import usersRoutes from "@/views/Companies/Employee/users.routes";
import templatesRoute from "@/views/Companies/Templates/templates.routes";

export default {
  routes: [
    {
      path: "",
      component: () => import("@/views/Companies/WrapperView.vue"),
      beforeEnter: guestGuard,
      children: [
        ...contractsRoute.routes,
        ...settingsRoute.routes,
        ...userProfile.routes,
        ...usersRoutes.routes,
        ...templatesRoute.routes,
        ...contractsApprovesRoute.routes,
        ...contractsSigneRoute.routes,
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/views/Companies/Dashboard/Index.vue"),
          meta: {
            title: "SIDEBAR.HOME",
            pageType: "SIDEBAR.CONTRACTS",
            roles: ["admin"],
          },
          beforeEnter: roleGuard,
        },

        // {
        //   path: "/workflow",
        //   name: "workflow",
        //   component: () => import("./Workflow/WorkflowList.vue"),
        //   meta: {
        //     title: "SIDEBAR.WORKFLOWS",
        //     pageType: "SIDEBAR.employee_type",
        //     roles: ["admin"],
        //   },
        //   beforeEnter: roleGuard,
        // },
        {
          path: "/step-two",
          name: "stepTwo",
          component: () => import("@/views/StepTwo.vue"),
          meta: {
            title: "SIDEBAR.PLANS",
            pageType: "SIDEBAR.employee_type",
            roles: ["admin"],
          },
          beforeEnter: roleGuard,
        },
      ],
    },
    {
      path: "/plans",
      name: "plans",
      component: () => import("./Plans/PlansPage.vue"),
      meta: {
        title: "SIDEBAR.PLANS",
        pageType: "SIDEBAR.employee_type",
        roles: ["admin"],
      },
      beforeEnter: roleGuard,
    },
  ],
};
