import { roleGuard } from "@/helper/router.helper";

export default {
  routes: [
    {
      path: "information",
      name: "contract-create-information",
      component: () => import("./ContractInformation.vue"),
      meta: {
        title: "SIDEBAR.CONTRACTS",
        pageType: "SIDEBAR.TEMPLATES",
        roles: ["admin", "template_moderator", "authorized_employee"],
        step: 1,
      },
      beforeEnter: roleGuard,
    },
    {
      path: "parties/:id",
      name: "contract-create-parties",
      component: () => import("./ContractParties.vue"),
      meta: {
        title: "SIDEBAR.CONTRACTS",
        pageType: "CONTRACT.CREATE",
        roles: ["admin", "template_moderator", "authorized_employee"],
        step: 2,
      },
      beforeEnter: roleGuard,
    },
    {
      path: "signature/:id",
      name: "contract-create-signature",
      component: () => import("./ContractSignature.vue"),
      meta: {
        title: "SIDEBAR.CONTRACTS",
        pageType: "CONTRACT.CREATE",
        roles: ["admin", "template_moderator", "authorized_employee"],
        step: 3,
      },
      beforeEnter: roleGuard,
    },
    {
      path: "preview/:id",
      name: "contract-create-preview",
      component: () => import("./ContractPreview.vue"),
      meta: {
        title: "SIDEBAR.CONTRACTS",
        pageType: "CONTRACT.CREATE",
        roles: ["admin", "template_moderator", "authorized_employee"],
        step: 4,
      },
      beforeEnter: roleGuard,
    },
  ],
};
