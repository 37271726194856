import { useUserProfileStore } from "@/stores/auth/user.store";

export const authGuard = (to, from, next) => {
  const tokenExists = $cookies.isKey("token_wtheq_app");
  if (tokenExists) {
    // Token exists, redirect to contracts page
    next("/dashboard");
  } else {
    // Token doesn't exist, allow navigation to the requested route
    next();
  }
};

export const guestGuard = (to, from, next) => {
  const tokenExists = $cookies.isKey("token_wtheq_app");
  if (!tokenExists) {
    next("/");
  } else {
    next();
  }
};

export const roleGuard = async (to, from, next) => {
  const userStore = useUserProfileStore();
  // fetch user from store
  if (Object.keys(userStore.account).length === 0) {
    await userStore.fetchUser();
  }

  const user = userStore.account;

  const roles = to.meta.roles;

  if (roles.includes(user.role?.value)) {
    next();
  } else {
    if (user.role.value == "admin") {
      next("/dashboard");
    } else {
      next("/contracts");
    }
  }
};
