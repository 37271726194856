import {createApp} from "vue";
import App from "./App.vue";
import {registerPlugins} from "@/plugins";
import axiosClient from "@/common/HTTPClient";
import VueTelInput from "vue-tel-input";

const app = createApp(App);

registerPlugins(app);

window.axios = axiosClient;
app.use(VueTelInput).mount("#app");
