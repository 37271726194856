<template>
  <v-locale-provider :rtl="isRtl" v-if="fontIsLoaded">
    <router-view class="overflow-hidden" />
  </v-locale-provider>
</template>

<script>
import FontFaceObserver from "fontfaceobserver";

export default {
  data() {
    return {
      fontIsLoaded: false,
    };
  },
  async mounted() {
    // const lang = navigator.language.includes("ar") ? "ar" : "en";
    const lang = "ar";
    if (localStorage.getItem("localeLang") == null) {
      this.$i18n.locale = lang;
      localStorage.setItem("localeLang", lang);
    } else {
      this.$i18n.locale = localStorage.getItem("localeLang");
    }

    // var Tawk_API = Tawk_API || {};
    // var Tawk_LoadStart = new Date();

    // (function () {
    //   var s1 = document.createElement("script");
    //   var s0 = document.getElementsByTagName("script")[0];
    //   s1.async = true;
    //   s1.src = "https://embed.tawk.to/663ca71607f59932ab3d9c5a/1hteepc20";
    //   s1.charset = "UTF-8";
    //   s1.setAttribute("crossorigin", "*");
    //   s0.parentNode.insertBefore(s1, s0);
    // })();
    const font = new FontFaceObserver("Suisse Int'l", {
      weight: 400,
    });
    font.load().then(() => {
      this.fontIsLoaded = true;
    });
  },
  computed: {
    isRtl() {
      return this.$i18n?.locale == "ar";
    },

    deviceLangIsArabic() {
      return navigator.language.includes("ar") ? true : false;
    },
  },

  watch: {
    $route() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss">
body {
  background: #f8fafb;
  font-family: "Suisse Int'l";
  font-weight: 400;
  font-size: 14px;
}

.Vue-Toastification__container {
  z-index: 10000000000 !important;
  .Vue-Toastification__toast-body {
    direction: rtl;
  }
}

.v-autocomplete .v-field .v-field__input > input::placeholder {
  letter-spacing: 0;
  font-size: 14px;
  opacity: 1;
  color: rgb(102, 102, 102);
}
</style>
