import errors from "./errors.json";
import auth from "./auth.json";
import sidebar from "./sidebar.json";
import contracts from "./contracts.json";
import contract_approves from "./contract_approves.json";
import contract_sign from "./contract_sign.json";
import contract from "./contract.json";
import workflow from "./workflow.json";
import employee from "./employee.json";
import authorized from "./authorized.json";
import payment from "./payment.json";
import global from "./global.json";
import template from "./template.json";
import company_details from "./company_details.json";
import subscriptions from "./subscriptions.json";
import profile from "./profile.json";
import dashboard from "./dashboard.json";
import sms_verify from "./sms_verify.json";
import nafath_verify from "./nafath_verify.json";

export default {
  "$vuetify.open": "Open menu",
  "$vuetify.pagination.ariaLabel.root": "Pages transition",
  "$vuetify.pagination.ariaLabel.previous": "Previous page",
  "$vuetify.pagination.ariaLabel.next": "Next page",
  "$vuetify.pagination.ariaLabel.currentPage": "Current page",
  "$vuetify.pagination.ariaLabel.page": "Page",
  "$vuetify.close": "Close",
  "$vuetify.input.prependAction": "Action",
  "$vuetify.input.clear": "Clear",
  ...errors,
  ...auth,
  ...sidebar,
  ...contracts,
  ...workflow,
  ...employee,
  ...authorized,
  ...payment,
  ...global,
  ...template,
  ...contract,
  ...company_details,
  ...subscriptions,
  ...profile,
  ...dashboard,
  ...sms_verify,
  ...nafath_verify,
  ...contract_approves,
  ...contract_sign,
};
